




























































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const AppModule = namespace("AppModule");
const UserModule = namespace("UserModule");
@Component({})
export default class VHelp extends Vue {
  @AppModule.Getter
  readonly smallScreen!: boolean;
  @UserModule.State
  readonly email!: string;
  private feedback = "";
  private sending = false;
  private success = false;
  public async sendFeedback() {
    this.sending = true;
    this.success = false;
    setTimeout(async () => {
      await this.$store.dispatch("UserModule/sendFeedback", this.feedback);
      this.feedback = "";
      this.sending = false;
      this.success = true;
    }, 0);
  }
}
